import passwordUtilities from './form_utilities'
import translationsHelper from './translations'

(async function() {

  // Test if we are in register form context
  const form = document.querySelector('[data-form=register]')
  if(form == null) {
    return
  }

  const translations = await translationsHelper.getAppTranslations();

  document.querySelectorAll('[data-input=password]').forEach(password => {
    passwordUtilities.checkPassword(password)
    password.addEventListener('keyup', function () {
      if (passwordUtilities.checkPassword(password)) {
        setFieldValid(password);
        // deletion of error message
        if (password.parentElement.querySelector('.Form-errorMessage--single')) {
          password.parentElement.querySelector('.Form-errorMessage--single').remove();
        }
      } else {
        setFieldInvalid(password);
      }
    })
  })

  document.querySelectorAll('[data-input=basic]').forEach(input => {
    checkBasicRequiredField(input)
    input.addEventListener('change', function () {
      checkBasicRequiredField(input)
    })
  });

  document.querySelectorAll('[data-input=name]').forEach(input => {
    checkName(input)
    input.addEventListener('change', function () {
      checkName(input)
    })
  });

  document.querySelectorAll('[data-input=phone]').forEach(input => {
    checkPhone(input)
    input.addEventListener('change', function () {
      checkPhone(input)
    })
  });

  function setFieldValid(input) {
    input.closest('.Form-group').classList.remove('Form-group--error')
    input.closest('.Form-group').classList.add('Form-group--valid')
  }

  function setFieldInvalid(input) {
    input.closest('.Form-group').classList.remove('Form-group--valid')
    input.closest('.Form-group').classList.add('Form-group--error')
  }

  function setFieldNeutral(input) {
    input.closest('.Form-group').classList.remove('Form-group--valid')
    input.closest('.Form-group').classList.remove('Form-group--error')
  }

  function setFieldErrorMessage(input, message) {
    if (input.nextElementSibling !== null && input.nextElementSibling.classList.contains('Form-errorMessage--single')) {
      input.nextElementSibling.remove();
    }

    if (message !== null) {
      const error = document.createElement('p')
      error.classList.add('Form-errorMessage--single')
      error.innerText = message
      input.after(error)
    }
  }

  /**
   * Check first name and last name with a regex.
   * @param input
   */
  function checkName(input)
  {
    if (input.value == '') {
      setFieldNeutral(input)
      setFieldErrorMessage(input, null)
      return;
    }

    const regex = /^[-'a-zA-ZÀ-ÖØ-öø-ÿ]+[ \-'a-zA-ZÀ-ÖØ-öø-ÿ]*$/i
    if (regex.test(input.value)) {
      setFieldValid(input)
      setFieldErrorMessage(input, null)
    } else {
      setFieldInvalid(input)
      setFieldErrorMessage(input, translations['registration.contact.error_bad_last_name'])
    }
  }

  function checkPhone(input)
  {
    if (input.value == '') {
      setFieldNeutral(input)
      setFieldErrorMessage(input, null)
      return;
    }

    const regex = /\+[0-9]{5,}/
    if (regex.test(input.value)) {
      setFieldValid(input)
      setFieldErrorMessage(input, null)
    } else {
      setFieldInvalid(input)
      setFieldErrorMessage(input, translations['registration.contact.error_bad_phone_format'])
    }
  }

  /**
   * Checks whether or not an input is filled and colors the input in green if valid.
   * @param input
   */
  function checkBasicRequiredField(input) {
    if (input.value == "")
      input.closest('.Form-group').classList.remove('Form-group--valid')
    else
      input.closest('.Form-group').classList.add('Form-group--valid')
  }

  function removeError(formGroup) {
    if(formGroup.querySelectorAll('.Form-errorMessage--single').length > 0) {
      formGroup.querySelectorAll('.Form-errorMessage--single').forEach( error => {
        error.remove()
      })
    }
  }

}());