(function() {
  const select = document.querySelector('.Select')
  const btn = select.querySelector('.Select-label')
  const list = select.querySelector('.Select-list')
  const buttonLangList = select.querySelectorAll('.Select-button')

  btn.addEventListener("mouseover", () => {
    select.classList.add("show")
  })

  btn.addEventListener("mouseout", () => {
    select.classList.remove("show")
  })

  list.addEventListener("mouseover", () => {
      select.classList.add("show")
  })

  document.addEventListener("click", (event) => {
    if (event.target.closest('.Select')) return

    if(select.classList.contains("show")) {
      select.classList.remove("show")
    }
  })

  buttonLangList.forEach((button) => {
    button.addEventListener("click", () => {
      const langId = button.dataset.value
      const loc = document.location

      const currentLang = loc.pathname.split("/")[1]
      const newUrl = loc.pathname.replace(currentLang, langId)

      document.location.replace(`${loc.protocol}//${loc.hostname}${newUrl}`)
    })
  })

}());
