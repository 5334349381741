
/**
 * Checks password format and updates requirements status in the help pop-in.
 * @param passwordInput
 */
function checkPassword(passwordInput) {
  let passwordStrength = 0

  // Length validation (at least 8 chars)
  if (passwordInput.value.length >= 8) {
    passwordStrength++
    document.querySelector('#password_has_min_length').classList.add('Popover-item--valid')
  }
  else {
    document.querySelector('#password_has_min_length').classList.remove('Popover-item--valid')
  }

  // At least 1 uppercase validation
  if (/[A-Z]/.test(passwordInput.value)) {
    passwordStrength++
    document.querySelector('#password_has_uppercase').classList.add('Popover-item--valid')
  }
  else {
    document.querySelector('#password_has_uppercase').classList.remove('Popover-item--valid')
  }

  // At least 1 lowercase validation
  if (/[a-z]/.test(passwordInput.value)) {
    passwordStrength++
    document.querySelector('#password_has_lowercase').classList.add('Popover-item--valid')
  }
  else {
    document.querySelector('#password_has_lowercase').classList.remove('Popover-item--valid')
  }

  // At least 1 integer validation
  if (/[0-9]/.test(passwordInput.value)) {
    passwordStrength++
    document.querySelector('#password_has_integer').classList.add('Popover-item--valid')
  }
  else {
    document.querySelector('#password_has_integer').classList.remove('Popover-item--valid')
  }

  // At least 1 special char validation
  if (/[^a-zA-Z0-9]/.test(passwordInput.value)) {
    passwordStrength++
    document.querySelector('#password_has_special_char').classList.add('Popover-item--valid')
  }
  else {
    document.querySelector('#password_has_special_char').classList.remove('Popover-item--valid')
  }

  // Update of the strength flag
  const colorFlagElement = document.querySelector('.Form-passwordIndication');
  const textFlagElement = document.querySelector('.Form-pwMessage');
  if (passwordStrength >=0 && passwordStrength < 3) { // Low strength password
    textFlagElement.innerHTML = "Faible";
    colorFlagElement.classList.add('Form-passwordIndication--low');
    colorFlagElement.classList.remove('Form-passwordIndication--valid');
    colorFlagElement.classList.remove('Form-passwordIndication--medium');
  }
  else if (passwordStrength >= 3 && passwordStrength < 5) { // Medium strength password
    textFlagElement.innerHTML = "Moyen";
    colorFlagElement.classList.add('Form-passwordIndication--medium');
    colorFlagElement.classList.remove('Form-passwordIndication--valid');
    colorFlagElement.classList.remove('Form-passwordIndication--low');
  }
  else { // Strong password
    textFlagElement.innerHTML = "Fort";
    colorFlagElement.classList.add('Form-passwordIndication--valid');
    colorFlagElement.classList.remove('Form-passwordIndication--medium');
    colorFlagElement.classList.remove('Form-passwordIndication--low');
    passwordInput.closest('.Form-group').classList.add('Form-group--valid')
    passwordInput.closest('.Form-group').classList.remove('Form-group--valid')
    return true;
  }

  return false;
}

export default {
  checkPassword
}
