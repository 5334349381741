// Enable SVG <use xlink:href> support in IE11
import svg4everybody from "svg4everybody";
svg4everybody();

// Import each icon that we want to include in our SVG sprite
// (built by Webpack + svg-sprite-loader)
import "./profile.svg";
import "./cart.svg";
import "./circled-plus.svg";
import "./circled-minus.svg";
import "./call.svg";
import "./calendar.svg";
import "./column.svg";
import "./contentblockleft.svg";
import "./contentblockfull.svg";
import "./contentblockfour.svg";
import "./expert.svg";
import "./social-linkedin.svg";
import "./social-pinterest.svg";
import "./social-facebook.svg";
import "./social-youtube.svg";
import "./linkedin-widget.svg";
import "./pinterest-widget.svg";
import "./facebook-widget.svg";
import "./youtube-widget.svg";
import "./messagethin.svg";
import "./toolsthin.svg";
import "./promothin.svg";
import "./calendarthin.svg";
import "./search.svg";
import "./document.svg";
import "./timer.svg";
import "./favorites.svg";
import "./trainingthicker.svg";
import "./stardefault.svg";
import "./staractive.svg";
import "./notice.svg";
import "./videoplay.svg";
import "./videoplay-outline.svg";
import "./angle.svg";
import "./angle-left.svg";
import "./angle-left-white.svg";
import "./angle-thin-down.svg";
import "./anglegray2.svg";
import "./star.svg";
import "./starbanner.svg";
import "./download.svg";
import "./file.svg";
import "./visuels.svg";
import "./videos.svg";
import "./basetarif.svg";
import "./backtotop.svg";
import "./arrowdownred.svg";
import "./menu.svg";
import "./euro.svg";
import "./timerthermor.svg";
import "./icontop3.svg";
import "./icontop3spare.svg";
import "./trainingtype.svg";
import "./twopeople.svg";
import "./counter.svg";
import "./staravis.svg";
import "./bubble.svg";
import "./mapwithpoints.svg";
import "./check.svg";
import "./difficulte1.svg";
import "./difficulte2.svg";
import "./difficulte3.svg";
import "./temps_remplacement.svg";
import "./icon_filled.svg";
import "./delivery.svg";
import "./accordion.svg";
import "./garantie.svg";
import "./anglegray2.svg";
import "./bin.svg";
import "./bocommercial.svg";
import "./lock.svg";
import "./time.svg";
import "./settings.svg";
import "./truck.svg";
import "./thermorlion-thicker.svg";
import "./storelocator.svg";
import "./videos.svg";

// Icons used in backoffice

import "./bofaq.svg";
import "./boexpertfull.svg";
import "./bothermorlion.svg";
import "./botraining.svg";
